exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-clients-history-tsx": () => import("./../../../src/pages/clients-history.tsx" /* webpackChunkName: "component---src-pages-clients-history-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-customer-stories-story-1-mdx": () => import("./../../../src/pages/customer-stories/story1.mdx" /* webpackChunkName: "component---src-pages-customer-stories-story-1-mdx" */),
  "component---src-pages-customer-stories-story-2-mdx": () => import("./../../../src/pages/customer-stories/story2.mdx" /* webpackChunkName: "component---src-pages-customer-stories-story-2-mdx" */),
  "component---src-pages-customer-stories-story-3-mdx": () => import("./../../../src/pages/customer-stories/story3.mdx" /* webpackChunkName: "component---src-pages-customer-stories-story-3-mdx" */),
  "component---src-pages-functions-tsx": () => import("./../../../src/pages/functions.tsx" /* webpackChunkName: "component---src-pages-functions-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-integrations-tsx": () => import("./../../../src/pages/integrations.tsx" /* webpackChunkName: "component---src-pages-integrations-tsx" */)
}

